import {Container, Row, Col, Button} from 'react-bootstrap';
import routes from "../helpers/routes";
import {Link} from "react-router-dom";

const HomePage = () => {
    return (
        <Container>
            <Row className="mt-5">
                <Col xs={{span: 12}} md={{span: 6}} className="mb-5">
                    <h2 className="mb-3">Bienvenido a la plataforma para el control del Comedor Iser</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus adipisci aliquid assumenda at consectetur deserunt dolores et excepturi fugiat harum ipsam magnam obcaecati odio, omnis porro quo recusandae repellendus voluptatem!</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque, possimus, vero? At dignissimos et illum perspiciatis rem tempora veritatis! Eum quidem repellendus rerum similique. Est facere iusto placeat sunt veniam?</p>
                    <div>
                        <Button as={Link} variant="outline-danger" to={routes.login}>Ingreso</Button>
                    </div>
                </Col>
                <Col>
                    <img
                        className="img-fluid"
                        src="/img/task-manager.svg"
                        alt="Gestor de tareas"/>
                    <p className="text-center font-weight-bold">COMEDOR ISER</p>
                </Col>
            </Row>
        </Container>
    );
};

export default HomePage;
